.home-main{
    display: flex;
    margin-top: 30px;
     
    width: 80%;
    margin: auto;
     gap: 10%;
}
.home-banner{
    width: 100%;
    height: 100%;
}
.home-main-1{
    width:45%;
     
}
.home-main-2{
    width: 40%;
    
}
.home-about-1{
    color:   #E53E3E;
    text-align: center;
    margin-top: 100px;
    word-spacing: 2px;
    text-decoration: dashed;
    font-family: 'Poppins', sans-serif;
}
.home-about{
   
    font-weight: 1000;
    font-size: 1600;
    
}
.current-offer{
     
    text-align: center;
    word-spacing: 2px;
    margin-top: -30px;
}
.current-offer-1{
     
    word-spacing: 2px;
    text-align: justify;
}
.services{
    text-align: center;
    width:80%;
    margin: auto;
    margin-top: 50px;
    color:   #E53E3E;
}
.services1{
    text-align: justify;
    width:80%;
    margin: auto;
    margin-top: 50px; 
}
.services-img-main{
    width: 80%;
    margin: auto;
}
.services-img{
    width: 100%;
    margin: auto;
    margin-top: 20px;
}
.contact-img{
     margin-left: 40%;
}
.contact-details{
    width: 80%;
    margin: auto;
    display: flex;
    gap:20%;
}
.office-address{
    width: 40%;
}
.footer-page{
    background-color: black;
    width: 100%;
    height: 200px;
     
}
.footer-top{
    display: flex;
    width: 40%;
    margin: auto;
    height: 150px;
}
.footer-des2{
    color: rgba(255,219,102);
     
}
.footer-des1{
    color: rgb(217, 211, 211);
}
.footer-join-btn{
    background-color: #E53E3E;
    color: white;
    border: none;
    width: 150px;
    border-radius: 15px;
    height: 60px;
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: larger;
    
}
.footer-join-btn:hover{
    cursor: pointer;
}

@media  screen and (max-width: 600px){
   .home-main{
    display: flex;
    flex-direction: column;
   } 
   .home-main-1{
    width: 100%;

   }
   .home-main-2{
    width: 100%;
   }
   .contact-img{
    margin-left: 5%;
   }
   .footer-top{
    width: 55%;
    margin: auto;
    display: flex;
    flex-direction: column;
   }
   .footer-arrow-img{
    width: 100%;
    height: 150px;
   }
   .footer-join-btn{
    background-color: #E53E3E;
    color: white;
    border: none;
    width: 100%;
    border-radius: 5px;
     height: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 1000;
    font-size: larger;
    
   }
   .footer-page{
    height: 450px;
    width: 100%;
    background-color: black;
    width: 100%;
   }
   .contact-details{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap:10%;
}
.office-address{
    width: 100%;
}

   /* .contact-details{

   } */
}
@media  screen and (max-width: 300px){

}
 
 