.navbar_Main{
    width: 100%;
    display:flex;
    justify-content: space-around;
    height: 80px;
    background-color: rgb(247, 242, 242);
    align-items: center;
     
     position: fixed;
     /* margin-top: -100px; */
}
a{
     
    font-weight: 500;
     font-size: large;
    text-decoration: none;
    word-spacing: 2px;
    height: fit-content;
}
.nav-img{
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

/* @media  screen and (max-width: 400px){
    .navbar_Main{
        display: flex;
        gap: 0px;
    }
    } */
@media  screen and (max-width: 600px){
    .navbar_Main{

        width: 100%;
    display:flex;
     gap:20px;
    height: 80px;
    background-color: rgb(247, 242, 242);
    align-items: center;
     
     position: fixed;
     /* margin-top: -120px; */
     word-wrap: break-word;
      
    }
    
}
 