.message-main{
    /* margin-top: 100px; */
    background-color:white;
    height: 650px;
    width:100%;
     
}
.form-main{
    display:grid;
    width: 40%;
    margin: auto;
    box-shadow: 2px 2px 2px 5px rgb(210, 210, 224);
    row-gap: 10%;
    height: 500px;
    margin-top: 50px;
    padding: 50px;
    border-top:  3px solid rgb(94, 94, 228);
}
input{
    outline: none;
    padding: 10px;
    border: 2px solid turquoise;
}
textarea{
    outline: none;
    padding: 10px;
    border:2px solid turquoise;
}

@media  screen and (max-width: 600px){
    .form-main{
        width: 60%;
          
         
        box-shadow: none;
        display: grid;
        row-gap: 10%;
        border: 2px solid rgb(121, 121, 232);
    }
    .message-main{
        /* margin-top: 100px; */
        background-color:white;
        height: 650px;
        width:100%;
         
    }
}