.growth-about-1{
    margin-top: 100px;
    width: 80%;
    margin: auto;
}
.growth-img{
    width: 100%;
    height: 900px;
}
.growth-main-3{
    width: 100%;
}
.growth-services{
    color:#E53E3E ;
    text-align: center;
}
.growth-message1{
    display: flex;
    text-align: justify;
}
.growth-message2{
    display: flex;
    text-align: justify;
}